import request from "@/utils/request";

export const analysis_save = data => {
	return request({
		url: " /analysis/analysis/save",
		method: "POST",
		data,
	});
};
export const deleteTree = params => {
	return request({
		url: "/analysis/userGroup/tree/delete",
		method: "GET",
		params,
	});
};

export const environment_index = data => {
	return request({
		url: "/client_v1/environment/index",
		method: "POST",
		data,
	});
};

//环境 - 首页统计信息
export const environment_first_count = data => {
	return request({
		url: "/client_v1/environment/first-count",
		method: "POST",
		data,
	});
};
//从官网获取视频列表
export const videoCourseList = data => {
	return request({
		url: "/web_v1/article/help-center",
		method: "GET",
		data,
	});
};
//通知获取消息列表
export const messageList = data => {
	return request({
		url: "/client_v1/message/list",
		method: "POST",
		data,
	});
};
//通知获取消息详情
export const messageDetail = data => {
	return request({
		url: "/client_v1/message/detail",
		method: "POST",
		data,
	});
};
//设置为已读消息
export const readMessage = data => {
	return request({
		url: "/client_v1/message/set-read",
		method: "POST",
		data,
	});
};
