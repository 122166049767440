export default {
	// 集中处理websocket响应的消息
	methods: {
		// /connection/success
		_connection_success(response) {
			this.connectSuccess = true;
			localStorage.version = response.version; // 这里是存储到 本地浏览器里的
			localStorage.mask = response.mask;
			localStorage.platform = response.platform;
			this.$store.commit("updateCommonParams", response);
			if (localStorage.token) {
				let params = {
					message: "/connection/send_configdata",
					token: localStorage.token,
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			}
		},
		//设备mac地址
		// /connection/device_info
		_connection_device_info(response) {
			localStorage.mac_address = response.mac_address;
		},
		//自有设备检测信息
		// /connection/testProxy
		_connection_testProxy(response) {
			let c_2 = JSON.parse(JSON.stringify(response));
			if (response.device_name.includes("\\u")) {
				c_2.device_name = this.unescapeUnicode(response.device_name);
			}
			this.$store.commit("updateIp", c_2);
		},
		//获取本地字体列表
		// /connection/fontlist
		_connection_fontlist(response) {
			let str = this.unescapeUnicode(response.data);
			str = str.slice(0, str.length - 1);
			let obj = {
				data: str.split(","),
			};
			if (response.font_datas) {
				obj.font_datas = JSON.parse(response.font_datas);
			}
			this.$store.commit("updateLocalFontlist", obj);
		},
		// /connection/downloadPlug
		_connection_downloadPlug(response) {
			this.$store.commit("updateInstall", response);
		},
		// /connection/deletePlug
		_connection_deletePlug(response) {
			this.$store.commit("updateUnInstall", response);
		},
		// 环境运行状态
		// /connection/env_status_msg
		_connection_env_status_msg(response) {
			let origin_status = JSON.parse(JSON.stringify(this.$store.state.curEnvStatusList));
			this.$store.commit("updateCurEnvStatusList", _.merge(origin_status, response.env_data));
		},
		// 打开环境响应
		// /connection/proxy
		_connection_proxy(response) {
			if (response.error_msg) this.$message.error(response.error_msg);
		},
		// 批量打开环境
		// /connection/multiopen
		_connection_multiopen(response) {
			if (response.failed_list.length > 0) {
				// 批量打开失败
				let origin_status = JSON.parse(JSON.stringify(this.$store.state.curEnvStatusList));
				let env_data = {};
				response.failed_list.forEach(item => {
					env_data = Object.assign(env_data, item);
				});
				// 更新状态
				Object.keys(env_data).forEach(item => {
					origin_status[item] = "standby";
				});
				this.$store.commit("updateCurEnvStatusList", origin_status);
				// 提示
				const msg = [...new Set(Object.values(env_data))];
				msg.forEach(item => {
					this.$message.error(item);
				});
			}
		},
		// 打开环境执行RPA
		// /connection/open_rpa_env
		_connection_open_rpa_env(response) {
			this.$store.commit("updateRpaMessage", response);
		},
	},
};
