<template>
  <a-modal
    :width="400"
    :destroyOnClose="true"
    dialogClass="notify_modal"
    wrapClassName="notify_modal_wrap"
    :maskStyle="{ backgroundColor: 'transparent', left: '40px' }"
    :visible="isShowNotify"
    @cancel="$emit('update:isShowNotify', false)"
    :footer="null"
  >
    <template slot="title"><a-icon type="reload" @click="getList" /></template>
    <div class="notify_container">
      <div class="tabs">
        <a-badge :count="notReadNum" style="margin-right: 32px">
          <div
            class="tabs_item"
            :class="{ active: !read_status }"
            @click="changeType(false)"
          >
            未读
          </div>
        </a-badge>
        <a-badge count="0" style="margin-right: 108px">
          <div
            class="tabs_item"
            :class="{ active: read_status }"
            @click="changeType(true)"
          >
            已读
          </div>
        </a-badge>
        <a-checkbox v-model="checked" @change="onChange">
          全部标记为已读
        </a-checkbox>
      </div>
      <!-- 消息列表 -->
      <div class="notify_list" v-show="list.length">
        <div
          class="notify_item"
          v-for="item in list"
          :key="item.id"
          @click="goToDetail(item.id)"
        >
          <img src="@/assets/img/notify.png" alt="" />
          <div class="item_info">
            <p class="item_title">{{ item.title }}</p>
            <p class="item_date">{{ item.send_time }}</p>
          </div>
        </div>
      </div>
      <!-- 空内容 -->
      <div class="noMessage" v-show="!list.length">
        <img src="@/assets/img/noMessage.png" alt="" class="img" />
        <p class="title">暂无新消息</p>
      </div>
      <!-- 底部 -->
      <div class="footer" @click="goNotifyCenter">
        <img src="@/assets/img/消息中心.png" class="img" alt="" />
        消息中心
        <a-icon type="right" style="font-size: 14px" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { messageList, readMessage } from "@/api/home.js";
export default {
  props: ["isShowNotify"],
  data() {
    return {
      checked: false,
      type: "notRead",
      currentPage: 1,
      total: 50,
      pageSize: "10",
      list: [],
      readedList: [],
      notReadList: [],

      // notReadNum: 0,
      read_status: false,
    };
  },
  computed: {
    notReadNum() {
      return this.$store.state.notReadNum;
    },
  },
  watch: {
    isShowNotify(newVal) {
      if (newVal) {
        this.getList();
      }
    },
    notReadNum() {
      this.getList();
    },
  },
  mounted() {},
  destroyed() {},
  methods: {
    getList() {
      messageList().then((res) => {
        if (res.data.code == 200) {
          const data = res.data.data;
          this.readedList = data.list.filter((item) => item.read_status);
          this.notReadList = data.list.filter((item) => !item.read_status);

          // this.notReadNum = this.notReadList.length;
          this.$store.commit("updatenotReadNum", this.notReadList.length);
          if (this.read_status) {
            this.list = this.readedList;
          } else {
            this.list = this.notReadList;
          }
          this.total = this.list.length;
        }
      });
    },
    goToDetail(id) {
      this.$router.push("/manage/notify-detail?id=" + id);
    },
    changeType(type) {
      this.read_status = type;
      this.list = type ? this.readedList : this.notReadList;
    },
    callback() {},
    onChange() {
      if (this.checked) {
        this.$confirm({
          title: "确定将所有消息设置为已读状态吗?",
          onOk: () => {
            const promiseArr = this.notReadList.map((item) =>
              readMessage({ id: item.id })
            );
            Promise.all(promiseArr).then((res) => {
              this.getList();
            });
          },
          onCancel() {},
        });
      }
    },

    goNotifyCenter() {
      this.$router.push("/manage/notify");
      this.$emit("update:isShowNotify", false);
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.notify_container {
  height: 100%;
  .header {
    .flex();
  }
  ::v-deep .ant-scroll-number-only > p.ant-scroll-number-only-unit {
    height: 20px !important;
  }
  .tabs {
    margin-top: 30px;
    border-bottom: 1px solid #dedfe2;
    .flex(flex-start);
    .tabs_item {
      width: 32px;
      height: 32px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #878fa7;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      &.active {
        font-size: 16px;
        font-weight: 500;
        color: #374567;
        border-bottom: 2px solid #4c84ff;
      }
    }
  }
  .notify_list {
    padding-top: 16px;
    max-height: 650px;
    overflow-y: auto;
    .notify_item {
      margin-bottom: 10px;
      .flex(flex-start);
      cursor: pointer;
      .item_info {
        width: 296px;
        margin-left: 12px;
        .item_title {
          margin-bottom: 2px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #374567;
          .ellipsis();
          &:hover {
            color: #4c84ff;
          }
        }
        .item_date {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .noMessage {
    margin: 20px auto 20px;
    text-align: center;
    .title {
      margin-top: 14px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #374567;
      line-height: 20px;
      text-align: center;
    }
  }
  .footer {
    position: absolute;
    left: 28px;
    bottom: 10px;
    width: 110px;
    height: 46px;
    cursor: pointer;
    .flex();
    &:hover {
      color: #4c84ff;
      .anticon-right {
        color: #4c84ff;
      }
    }
  }
}
// ::v-deep .ant-scroll-number.ant-badge-count {
//   min-width: 16px;
//   min-height: 16px;
// }
::v-deep p.ant-scroll-number-only-unit {
  height: 16px;
}
</style>
<style lang="less">
.notify_modal_wrap.notify_modal_wrap.notify_modal_wrap {
  left: 40px;
}
.notify_modal.notify_modal.notify_modal {
  top: 0px;
  left: 0px;
  margin: 0;
  width: 400px;
  padding-bottom: 0;
  .ant-modal-content {
    height: 100vh;
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-modal-header {
    padding-bottom: 0;
    border-bottom: none;
  }
}
</style>