<template>
	<a-config-provider :locale="locale">
		<div id="app">
			<LeftNav />
			<router-view v-if="connectSuccess" />
		</div>
	</a-config-provider>
</template>
<script>
// 修改 antd 的语言为中文
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
// import { log } from 'console';
import LeftNav from "@/components/LeftNav";
import _ from "lodash";
import websocketResponseHandler from "./mixixs/websocketResponseHandler";
export default {
	components: {
		LeftNav,
	},
	data() {
		return {
			locale: zhCN,
			websocketObj: null,
			websocket_port: 42536,
			connectSuccess: false,
		};
	},
	mixins: [websocketResponseHandler],
	created() {
		//清除浏览器本地关于版本号和平台信息的存储，通过websocket再次获取
		localStorage.removeItem("version");
		localStorage.removeItem("mask");
		localStorage.removeItem("platform");
		this.initWebsocket();
		this.$store.commit("setWebsocket", this.websocketObj);
	},
	mounted() {},
	methods: {
		unescapeUnicode(str) {
			return str.replace(/\\u([a-fA-F0-9]{4})/g, function (g, m1) {
				return String.fromCharCode(parseInt(m1, 16));
			});
		},
		//websocket
		initWebsocket() {
			this.websocketObj = new WebSocket("ws://" + "127.0.0.1:" + this.websocket_port);

			Object.defineProperty(navigator, "hardwareConcurrency", {
				value: "100",
			});

			this.websocketObj.onmessage = this.onMessage;
			this.websocketObj.onopen = this.onOpen;
			this.websocketObj.onerror = this.onError;
			this.websocketObj.onclose = this.onClose;
			// 重写websocket的send方法，发送之前在控制台打印发送的内容
			if (process.env.NODE_ENV == "development") {
				const newSend = this.websocketObj.send;
				this.websocketObj.send = data => {
					console.log("⬆️", this.$decrypt(data));
					newSend.call(this.websocketObj, data);
				};
			}
		},
		//接收到消息的回调方法
		onMessage(evt) {
			const response = this.$decrypt(evt.data);
			if (process.env.NODE_ENV == "development") console.log("⬇️", Object.freeze(response));
			const handlerName = response.message.replaceAll("/", "_");
			if (this[handlerName]) this[handlerName](response);
		},
		//连接成功建立的回调方法
		onOpen() {
			if (this.websocketObj.readyState === 1) {
				this.get_client_params();
			}
		},
		//连接发生错误
		onError() {
			if (this.websocket_port <= 42540) {
				setTimeout(() => {
					this.onClose();
					this.websocket_port += 1;
					this.initWebsocket();
				}, 1000);
			} else {
				this.$message.error("websocket通信异常,请确保已打开快洋淘浏览器客户端或者重新打开软件1分钟后再试!");
				this.onClose();
			}
		},
		//连接关闭的回调方法
		onClose() {
			this.websocketObj && this.websocketObj.close && this.websocketObj.close();
		},
		get_client_params() {
			let response = `{"message": "/connection/success"}`;
			// 加密
			// const XORencryption = val => {
			// 	if (typeof val !== "string") return val;
			// 	val = this.$Base64.encode(val);
			// 	var m = "y";
			// 	let message = "";
			// 	for (var i = 0; i < val.length; i++) {
			// 		if (0 == i % 2) {
			// 			message += String.fromCharCode(val.charCodeAt(i) ^ m.charCodeAt(0));
			// 		} else {
			// 			message += val[i];
			// 		}
			// 	}
			// 	return this.$Base64.encode(message);
			// };
			let b_1 = this.$encrypt(response);
			// let b_1 = response;

			this.websocketObj.send(b_1);
		},
	},
};
</script>
<style lang="less" scoped></style>
