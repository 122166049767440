// 加密
import { Base64 } from "js-base64";
export default function (val) {
	if (typeof val !== "string") return val;
	val = Base64.encode(val);
	var m = "y";
	let message = "";
	for (var i = 0; i < val.length; i++) {
		if (0 == i % 2) {
			message += String.fromCharCode(val.charCodeAt(i) ^ m.charCodeAt(0));
		} else {
			message += val[i];
		}
	}
	return Base64.encode(message);
}
