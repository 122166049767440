import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "@/store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
Vue.use(Antd);

import "./style/index.less";
import "normalize.css";
// import { Base64 } from "js-base64";
import encrypt from "@/utils/encrypt.js";
import decrypt from "@/utils/decrypt.js";
// import { Base64 } from '@/utils/base64.mjs'
// Vue.prototype.$Base64 = Base64;
Vue.prototype.$websocket = null;
Vue.config.productionTip = false;
Vue.prototype.$encrypt = encrypt;
Vue.prototype.$decrypt = decrypt;
window.$decrypt = decrypt;
window.vm = new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");
