import axios from "axios";
import Vue from "vue";

// let baseURL = "/api";
let baseURL = "https://api.yangtao.com/";
if (process.env.NODE_ENV === "production") {
	baseURL = "https://api.yangtao.com/";
}

const request = axios.create({
	baseURL: baseURL,
	timeout: 40000,
	// withCredentials: true, // 允许携带cookie
});

// version: " 1.0.0",
// mask: "desk",
// platform: "1",

// 请求拦截器
request.interceptors.request.use(
	config => {
		//判断请求的类型：如果是post请求就把默认参数拼到data里面；如果是get请求就拼到params里面

		if (config.method.toLowerCase() === "post") {
			config.data = {
				version: localStorage.version,
				mask: localStorage.mask,
				platform: localStorage.platform,
				// version: " 1.0.0",
				// mask: "desk",
				// platform: "1",
				app: null,
				token: localStorage.token,
				...config.data,
			};
		} else if (config.method.toLowerCase() === "get") {
			config.params = {
				version: localStorage.version,
				mask: localStorage.mask,
				platform: localStorage.platform,
				// version: " 1.0.0",
				// mask: "desk",
				// platform: "1",
				app: null,
				token: localStorage.token,
				...config.params,
			};
		}
		return config;
	},
	error => {
		Promise.reject(error);
	}
);

// 响应拦截器

request.interceptors.response.use(
	response => {
		if (response.data.code == 3) {
			// localStorage.removeItem("user_password");
			localStorage.removeItem("member");
			// localStorage.removeItem("user_phone");
			localStorage.removeItem("user");
			localStorage.removeItem("token");

			window.vm.$router.push("/login");

			location.reload();

			return response;
		}
		//code码为2说明缺少平台，浏览器版本等公共参数，可能是没打开快洋淘浏览器
		if (response.data.code == 2) {
			Vue.prototype.$message.error("连接异常，请刷新页面或联系客服");
			return response;
		}
		if (
			response.data.code != 200 &&
			response.data.code != 219 &&
			response.request.responseType != "arraybuffer"
		) {
			let c_1 = response.data.message || response.data.msg;
			Vue.prototype.$message.error(c_1);
			return response;
		}
		return response;
	},
	error => {
		const { code, message } = error;
		if (code === "ECONNABORTED" && message === "Network Error") {
			// 网络异常
			Vue.prototype.$message.error("网络异常,请检查网络连接");
			return Promise.reject(error);
		}
		if (code === "ECONNABORTED" && message.indexOf("timeout") != -1) {
			if (error.config.url === "/client_v1/device/reset-device") return; //该接口用于查询设备状态，靠是否超时来判断设备状态，所以此处不要报错
			// 请求超时
			Vue.prototype.$message.error("网络请求超时，请稍后重试");
			return Promise.reject(error);
		}

		Vue.prototype.$message.error(error.response.data.message);

		return error;
	}
);

export default request;
