// 解密
import { Base64 } from "js-base64";
export default function (val) {
	if (typeof val !== "string") return val;
	val = Base64.decode(val);
	let message = "";
	var m = "y";
	for (var i = 0; i < val.length; i++) {
		if (0 == i % 2) {
			message += String.fromCharCode(val.charCodeAt(i) ^ m.charCodeAt(0));
		} else {
			message += val[i];
		}
	}
	message = Base64.decode(message);
	return JSON.parse(message);
}
