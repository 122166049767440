<template>
	<a-modal
		title=""
		:width="400"
		:destroyOnClose="true"
		dialogClass="help_modal"
		wrapClassName="help_modal_wrap"
		:maskStyle="{ backgroundColor: 'transparent', left: '2.5rem' }"
		:visible="isShowHelp"
		@cancel="$emit('update:isShowHelp', false)"
		:footer="null">
		<div class="help_container">
			<!-- 寻求帮助顶部 -->
			<p class="top_title">寻求帮助</p>
			<div class="top_nav">
				<div class="top_nav_item" @click="goService">
					<img src="@/assets/img/helpCenter/在线咨询.png" alt="" class="img" />
					<p class="title">在线咨询 <a-icon type="right" style="font-size: 12px" /></p>
					<p class="desc">周一至周日 8:00-18:00</p>
				</div>
				<div class="top_nav_item" @click="goHelpCenter">
					<img src="@/assets/img/helpCenter/帮助中心.png" alt="" class="img" />
					<p class="title">帮助中心 <a-icon type="right" style="font-size: 12px" /></p>
					<p class="desc">图文教程、视频教程</p>
				</div>
			</div>
			<!-- 使用指南 -->
			<ul class="guide_list">
				<li class="list_top">
					<div class="list_top_left"><img src="@/assets/img/helpCenter/使用指南.png" alt="" class="img" />使用指南</div>
					<div class="list_top_right" @click="goMoreTxtCourse('')">更多 <a-icon type="right" style="font-size: 12px" /></div>
				</li>
				<li class="list_item" @click="goMoreTxtCourse(15)">
					<div class="list_item_left"><img src="@/assets/img/helpCenter/环境管理.png" alt="" class="img" />环境管理</div>
					<div class="list_item_center"></div>
					<div class="list_item_right">Environment management</div>
				</li>
				<li class="list_item" @click="goMoreTxtCourse(16)">
					<div class="list_item_left"><img src="@/assets/img/helpCenter/设备管理.png" alt="" class="img" />设备管理</div>
					<div class="list_item_center"></div>
					<div class="list_item_right">Device management</div>
				</li>
				<li class="list_item" @click="goMoreTxtCourse(17)">
					<div class="list_item_left"><img src="@/assets/img/helpCenter/费用管理.png" alt="" class="img" />费用管理</div>
					<div class="list_item_center"></div>
					<div class="list_item_right">Cost management</div>
				</li>
				<li class="list_item" @click="goMoreTxtCourse(18)">
					<div class="list_item_left"><img src="@/assets/img/helpCenter/企业管理.png" alt="" class="img" />企业管理</div>
					<div class="list_item_center"></div>
					<div class="list_item_right">Enterprise management</div>
				</li>
				<li class="list_item" @click="goMoreTxtCourse(19)">
					<div class="list_item_left"><img src="@/assets/img/helpCenter/安全中心.png" alt="" class="img" />安全中心</div>
					<div class="list_item_center"></div>
					<div class="list_item_right">Security center</div>
				</li>
				<li class="list_item" @click="goMoreTxtCourse(20)">
					<div class="list_item_left"><img src="@/assets/img/helpCenter/常见问题.png" alt="" class="img" />常见问题</div>
					<div class="list_item_center"></div>
					<div class="list_item_right">Common problem</div>
				</li>
			</ul>
			<!-- 视频教程 -->
			<div class="video_list">
				<div class="list_top">
					<div class="list_top_left"><img src="@/assets/img/helpCenter/视频教程.png" alt="" class="img" />一分钟视频教程</div>
					<div class="list_top_right" @click="goMoreVideoCourse">更多 <a-icon type="right" style="font-size: 12px" /></div>
				</div>
				<ul class="list_body">
					<li class="list_item" @click="goVideo(item.id)" v-for="item in videoCourseList" :key="item.id">
						<p class="video_title">{{ item.title }}</p>
						<p class="video_time">{{ item.duration }}</p>
						<img src="@/assets/img/helpCenter/播放.png" alt="" class="img" />
					</li>
				</ul>
			</div>
		</div>
	</a-modal>
</template>

<script>
import { videoCourseList } from "@/api/home.js";
export default {
	props: ["isShowHelp"],
	data() {
		return {
			checked: false,
			type: "notRead",
			videoCourseList: [],
		};
	},
	mounted() {
		this.getVideo();
	},
	destroyed() {},
	methods: {
		callback() {},
		onChange() {},
		changeType(type) {
			this.type = type;
		},
		goNotifyCenter() {
			this.$router.push("/manage/notify");
			this.$emit("update:isShowHelp", false);
		},
		//获取视频
		getVideo() {
			videoCourseList().then(res => {
				if (res.data.code == 200) {
					const list = res.data.data.help_video_list;
					list.forEach(async item => {
						item.content = "https:" + item.content;
						item.duration = await this.getVideoDuration(item.content);
					});
					this.videoCourseList = list;
				}
			});
		},
		//获取视频时长
		getVideoDuration(url) {
			return new Promise(reslove => {
				let video = document.createElement("video");
				video.preload = "metadata";
				video.src = url;
				video.onloadedmetadata = () => {
					//   reslove(parseInt((video.duration * 1000).toString(), 10));
					reslove(this.formatDuration(video.duration));
					video = null;
				};
			});
		},
		//处理视频时长
		formatDuration(time) {
			let minute = Math.floor(time / 60)
				.toString()
				.padStart(2, 0);
			let second = Math.floor(time % 60)
				.toString()
				.padStart(2, 0);
			return minute + ":" + second;
		},
		goService() {
			window.open("https://work.weixin.qq.com/kfid/kfce841fa55ddaa0789");
		},
		goHelpCenter() {
			window.open("https://www.yangtao.com/help");
		},
		goMoreTxtCourse(id) {
			window.open("https://www.yangtao.com/help/");
			// window.open("https://www.yangtao.com/help/more?invite&cate=15");
		},
		goVideo(id) {
			window.open("https://www.yangtao.com/help/detail/" + id + ".html");
			// window.open("https://www.yangtao.com/help/more?invite&cate=15");
		},
		goMoreVideoCourse() {
			window.open("https://www.yangtao.com/help/video");
		},
	},
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.help_container {
	padding-top: 1rem;
	height: 100%;

	.top_title {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2c354b;
		line-height: 22px;
	}
	.top_nav {
		margin: 16px auto 29px;
		.flex();
		.top_nav_item {
			width: 166px;
			height: 97px;
			padding: 12px;
			background: #ffffff;
			box-shadow: 0px 2px 9px 0px rgba(42, 78, 156, 0.1);
			border-radius: 4px;
			cursor: pointer;
			.img {
				width: 28px;
			}
			.title {
				margin: 8px auto 4px;
				font-size: 12px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2c354b;
				line-height: 17px;
			}
			&:hover {
				.title {
					color: #4c84ff;
					.anticon-right {
						color: #4c84ff;
					}
				}
			}
			.desc {
				font-size: 11px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #abb4c3;
				line-height: 16px;
			}
		}
	}
	.guide_list {
		.list_top {
			margin-bottom: 23px;
			.flex();
			.list_top_left {
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2c354b;
				line-height: 22px;
				.img {
					width: 20px;
					margin-right: 6px;
				}
			}
			.list_top_right {
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #2c354b;
				line-height: 17px;
				cursor: pointer;
				&:hover {
					color: #4c84ff;
					.anticon-right {
						color: #4c84ff;
					}
				}
			}
		}
		.list_item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			margin-bottom: 20px;
			z-index: 2;
			cursor: pointer;
			&:hover {
				.list_item_left {
					color: #4c84ff;
				}
				.list_item_center {
					border-color: #4c84ff;
				}
				.list_item_right {
					color: #4c84ff;
				}
			}
			.list_item_left {
				background-color: #fff;
				padding-right: 6px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #2c354b;
				img {
					margin-right: 6px;
				}
			}
			.list_item_right {
				background-color: #fff;
				padding-left: 6px;
				font-size: 12px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #ccc;
			}
			.list_item_center {
				position: absolute;
				width: 100%;
				border: 1px dashed #dedfe2;
				z-index: -2;
			}
		}
	}
	.video_list {
		margin-top: 31px;
		.list_top {
			margin-bottom: 21px;
			.flex();
			.list_top_left {
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2c354b;
				line-height: 22px;
				.img {
					width: 20px;
					margin-right: 6px;
				}
			}
			.list_top_right {
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #2c354b;
				line-height: 17px;
				cursor: pointer;
				&:hover {
					color: #4c84ff;
					.anticon-right {
						color: #4c84ff;
					}
				}
			}
		}
		.list_body {
			.flex(@wrap:wrap);
		}
		.list_item {
			position: relative;
			margin-bottom: 12px;
			width: 166px;
			height: 68px;
			padding: 12px 14px;
			background: url("~@/assets/img/helpCenter/视频封面.png") 0 0 / cover no-repeat;
			cursor: pointer;
			.video_title {
				margin-bottom: 6px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #374567;
				line-height: 17px;
			}
			.video_time {
				font-size: 12px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #999999;
				line-height: 17px;
			}
			.img {
				width: 16px;
				position: absolute;
				right: 12px;
				bottom: 12px;
			}
			&:hover {
				.video_title {
					color: #4c84ff;
				}
			}
		}
	}
}
::v-deep .ant-scroll-number.ant-badge-count {
	min-width: 1rem;
	min-height: 1rem;
}
::v-deep p.ant-scroll-number-only-unit {
	height: 1rem;
}
</style>
<style lang="less">
.help_modal_wrap.help_modal_wrap.help_modal_wrap {
	left: 2.5rem;
}
.help_modal.help_modal.help_modal {
	top: 0rem;
	left: 0rem;
	margin: 0;
	width: 25rem;
	padding-bottom: 0;
	.ant-modal-content {
		height: 100vh;
	}
	.ant-modal-body {
		padding-top: 0;
	}
	.ant-modal-header {
		padding-bottom: 0;
		border-bottom: none;
	}
}
</style>
