import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const store = new Vuex.Store({
	state: {
		websocket: null,
		ipInfo: null,
		install: null,
		unInstall: null,
		// envExCheck: null,
		localFontlist: {},
		commonParams: null, //公共参数
		notReadNum: 0,
		curEnvStatusList: {},
		operateEnvID: "",
		// RPA 相关
		isCopy: false,
		editTask: null,
		rpa_message: null,
	},
	getters: {
		allFontlist(state) {
			return state.localFontlist;
		},
	},
	mutations: {
		setWebsocket(state, val) {
			state.websocket = val;
		},
		updateIp(state, val) {
			state.ipInfo = val;
		},
		updateInstall(state, val) {
			state.install = val;
		},
		updateUnInstall(state, val) {
			state.unInstall = val;
		},
		// updateEnvExCheck(state, val) {
		// 	state.envExCheck = val;
		// },
		updateLocalFontlist(state, val) {
			state.localFontlist = val;
		},
		updateCommonParams(state, val) {
			state.commonParams = val;
		},
		updatenotReadNum(state, val) {
			state.notReadNum = val;
		},
		updateCurEnvStatusList(state, val) {
			state.curEnvStatusList = val;
		},
		updateOperateEnvID(state, val) {
			state.operateEnvID = val;
		},
		updateIsCopy(state, falg) {
			state.isCopy = falg;
		},
		updateEditTask(state, task) {
			state.editTask = task;
		},
		updateRpaMessage(state, msg) {
			state.rpa_message = msg;
		},
	},
});
export { store };
