<template>
	<div class="left_nav">
		<div class="nav_content nav_1" @click="nav_click('/manage')">管理</div>
		<div class="nav_content nav_2" @click="nav_click('/extension')">插件</div>
		<div class="nav_content nav_3" @click="nav_click('/extension/crossNavigation')">导航</div>
		<div class="nav_content nav_4" @click="nav_click('/select')">精选</div>
		<div class="nav_bottom" style="margin-top: 40vh">
			<a-badge :count="notReadNum">
				<div class="nav_item" @click="goNotifySide">
					<img src="@/assets/img/消息.png" alt="" />
					消息
				</div>
			</a-badge>

			<div
				class="nav_item"
				@click="
					() => {
						isShowNotify = false;
						isShowHelp = true;
					}
				"
			>
				<img src="@/assets/img/帮助.png" alt="" />
				帮助
			</div>
			<div class="nav_item" @click="goToSetting">
				<img src="@/assets/img/设置.png" alt="" />
				设置
			</div>
		</div>
		<NotifySidebar :isShowNotify.sync="isShowNotify" />
		<HelpSidebar :isShowHelp.sync="isShowHelp" />
	</div>
</template>

<script>
import NotifySidebar from '@/components/NotifySidebar';
import HelpSidebar from '@/components/HelpSidebar';
import { messageList } from '@/api/home.js';
export default {
	components: {
		NotifySidebar,
		HelpSidebar,
	},
	data() {
		return {
			isShowNotify: false,
			isShowHelp: false,
			// notReadNum: 0,
			notReadList: [],
		};
	},
	created() {
		// if (localStorage.token) {
		//   this.getList();
		// }
	},
	computed: {
		commonParams() {
			return this.$store.state.commonParams;
		},
		notReadNum() {
			return this.$store.state.notReadNum;
		},
	},
	watch: {
		$route(newValue, oldValue) {
			if ((oldValue.path == '/login/login' || oldValue.path == '/login/company') && newValue.path == '/manage/home') {
				this.getList();
			}
		},
		commonParams(newVal) {
			if (newVal && localStorage.token) this.getList();
		},
	},
	methods: {
		getList() {
			messageList().then((res) => {
				if (res.data.code == 200) {
					const data = res.data.data;
					// this.readedList = data.list.filter((item) => item.read_status);
					this.notReadList = data.list.filter((item) => !item.read_status);
					// this.readedNum = this.readedList.length;
					// this.notReadNum = this.notReadList.length;
					this.$store.commit('updatenotReadNum', this.notReadList.length);
					// if (this.read_status) {
					//   this.list = this.readedList;
					// } else {
					//   this.list = this.notReadList;
					// }
					// this.total = this.list.length;
				}
			});
		},
		nav_click(path) {
			this.isShowNotify = false;
			this.isShowHelp = false;
			if (path == '/extension') {
				sessionStorage.setItem('currentNav', 'extension');
			} else if (path == '/extension/crossNavigation') {
				sessionStorage.setItem('currentNav', 'crossNav');
			}
			this.$router.push({ path });
		},
		goNotifySide() {
			if (!localStorage.token) {
				this.$message.error('请先登录，然后进行查看');
				return;
			}
			this.isShowNotify = true;
			this.isShowHelp = false;
		},
		goToSetting() {
			//这个链接是与客户端约定的链接，跳转这个链接会打开插件界面
			window.open('chrome-extension://jdhkphdblepahognhnkmajbllkoidbda/setting.html');
		},
	},
};
</script>

<style lang="less" scoped>
.left_nav {
	z-index: 99;
	width: 40px;
	background-color: white;
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100%;
	border-right: 1px solid #dedfe2;

	.nav_content {
		width: 100%;
		height: 45px;
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: 6px 0px;
		background-size: 28px 28px;
		padding-top: 33px;
		text-align: center;
		font-size: 12px;
		color: #666;
	}
	.nav_bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.nav_item {
		margin-bottom: 20px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		text-align: center;
		cursor: pointer;
		img {
			display: block;
			width: 20px;
			height: 20px;
			margin: 0 auto 8px;
		}
	}
	.nav_content:hover {
		color: #4c84ff;
	}

	.nav_1 {
		margin-top: 10px;
		background-image: url('~@/assets/img/管理@2x.png');
	}

	.nav_2 {
		margin-top: 24px;
		background-image: url('~@/assets/img/插件@2x.png');
	}

	.nav_3 {
		background-image: url('~@/assets/img/店铺@2x.png');
		margin-top: 24px;
	}
	.nav_4 {
		margin-top: 24px;
		background-image: url('~@/assets/img/精选@2x.png');
	}
}
</style>
