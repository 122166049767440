import Vue from "vue";
import VueRouter from "vue-router";

const original = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
	return original.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("@/views/home.vue"),
		meta: {
			needLogin: false, // 不需要登录
		},
	},
	{
		path: "/setting",
		name: "setting",
		component: () => import("@/views/setting.vue"),
		meta: {
			needLogin: false, // 不需要登录
		},
	},
	{
		path: "/manage",
		name: "manage",
		component: () => import("@/views/manage/index.vue"),
		redirect: { name: "manage_home" },
		meta: {
			needLogin: true, // 需要登录
		},
		children: [
			{
				path: "notify",
				name: "notify",
				component: () => import("@/views/notify.vue"),
				meta: {
					needLogin: true, // 不需要登录
					ismember: true, //员工可见
				},
			},
			{
				path: "notify-detail",
				name: "notify-detail",
				component: () => import("@/views/notifyDetail.vue"),
				meta: {
					needLogin: true, // 不需要登录
					ismember: true, //员工可见
				},
			},
			{
				path: "home",
				name: "manage_home",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import(/* webpackChunkName: "manage_home" */ "@/views/manage/home/home.vue"),
			},
			// 测试页面，
			{
				path: "home_new",
				name: "manage_home_test",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import(/* webpackChunkName: "manage_home" */ "@/views/manage/home/home_new.vue"),
			},
			{
				path: "environment",
				name: "manage_environment",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import(/* webpackChunkName: "manage_environment" */ "@/views/manage/environment/environment.vue"),
			},
			{
				path: "environment_new",
				name: "manage_environment_new",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import(/* webpackChunkName: "manage_environment" */ "@/views/manage/environment/environment_new.vue"),
			},
			{
				path: "equipment",
				name: "manage_equipment",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import(/* webpackChunkName: "equipment" */ "@/views/manage/equipment/index.vue"),
			},
			{
				path: "fuwu",
				name: "manage_fuwu",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import(/* webpackChunkName: "equipment" */ "@/views/manage/equipment/fuwu.vue"),
			},
			{
				path: "buyequipment",
				name: "manage_buyequipment",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import(/* webpackChunkName: "buyequipment" */ "@/views/manage/equipment/buy_equipment.vue"),
			},
			{
				path: "renewal",
				name: "manage_renewal",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import(/* webpackChunkName: "buyequipment" */ "@/views/manage/equipment/enewal.vue"),
			},
			//企业管理
			{
				path: "enterprise",
				name: "manage_enterprise",
				redirect: "enterprise/member",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import(/* webpackChunkName: "manage_member" */ "@/views/manage/enterpriseManage"),
				children: [
					{
						path: "authentication",
						name: "manage_enterprise_authentication", //企业认证
						meta: {
							needLogin: true, // 需要登录
						},
						component: () => import(/* webpackChunkName: "manage_member" */ "@/views/manage/enterpriseManage/authentication/index.vue"),
					},
					{
						path: "department",
						name: "manage_enterprise_department", //部门管理
						meta: {
							needLogin: true, // 需要登录
						},
						component: () => import(/* webpackChunkName: "manage_member" */ "@/views/manage/enterpriseManage/departmentManage/edit_department.vue"),
					},
					{
						path: "member",
						name: "manage_enterprise_member", //成员管理
						meta: {
							needLogin: true, // 需要登录
						},
						component: () => import(/* webpackChunkName: "manage_member" */ "@/views/manage/enterpriseManage/memberManage/member.vue"),
					},
				],
			},
			{
				path: "edit_department",
				name: "edit_department",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import(/* webpackChunkName: "manage_member" */ "@/views/manage/enterpriseManage/departmentManage/edit_department.vue"),
			},
			{
				path: "addenv",
				name: "manage_addenv",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import(/* webpackChunkName: "addenv" */ "@/views/manage/environment/components/EditEnvironment.vue"),
			},

			{
				path: "editenv",
				name: "manage_editenv",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import(/* webpackChunkName: "addenv" */ "@/views/manage/environment/components/EditEnvironment.vue"),
			},
			// {
			// 	path: "add-environment",
			// 	name: "AddEnvironment",
			// 	meta: {
			// 		needLogin: true,
			// 	},
			// 	component: () => import("@/views/manage/environment/components/EditEnvironment.vue"),
			// },
			// {
			// 	path: "edit-environment",
			// 	name: "EditEnvironment",
			// 	meta: {
			// 		needLogin: true,
			// 	},
			// 	component: () => import("@/views/manage/environment/components/EditEnvironment.vue"),
			// },
			{
				path: "edituser",
				name: "manage_edituser",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import(/* webpackChunkName: "edituser" */ "@/views/manage/environment/components/edit_user.vue"),
			},
			{
				path: "security",
				name: "manage_security",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import(/* webpackChunkName: "security" */ "@/views/manage/security/index.vue"),
				redirect: { name: "member_security" },
				children: [
					{
						path: "member",
						name: "member_security",
						component: () => import(/* webpackChunkName: "member" */ "@/views/manage/security/member.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
					{
						path: "terminal",
						name: "terminal_security",
						component: () => import(/* webpackChunkName: "terminal" */ "@/views/manage/security/terminal.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
				],
			},
			{
				path: "fee",
				name: "manage_fee",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import(/* webpackChunkName: "fee" */ "@/views/manage/fee/index.vue"),
				redirect: { name: "charge_fee" },
				children: [
					{
						path: "charge",
						name: "charge_fee",
						component: () => import(/* webpackChunkName: "charge_fee" */ "@/views/manage/fee/charge_fee.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
					{
						path: "order",
						name: "order_fee",
						component: () => import(/* webpackChunkName: "order_fee" */ "@/views/manage/fee/order_fee.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
					{
						path: "balance",
						name: "balance_fee",
						component: () => import(/* webpackChunkName: "balance_fee" */ "@/views/manage/fee/balance_fee.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
					{
						path: "invite",
						name: "invite_fee",
						component: () => import(/* webpackChunkName: "invite_fee" */ "@/views/manage/fee/invite_fee.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
					{
						path: "cash",
						name: "invite_cash",
						component: () => import(/* webpackChunkName: "invite_fee" */ "@/views/manage/fee/cash.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
					{
						path: "coupon",
						name: "coupon_fee",
						component: () => import(/* webpackChunkName: "coupon_fee" */ "@/views/manage/fee/coupon_fee.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
					{
						path: "ballot",
						name: "ballot_fee",
						component: () => import(/* webpackChunkName: "ballot_fee" */ "@/views/manage/fee/ballot_fee.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
					{
						path: "cash",
						name: "getCash",
						component: () => import(/* webpackChunkName: "ballot_fee" */ "@/views/manage/fee/cash.vue"),
						meta: {
							needLogin: true, // 需要登录
						},
					},
				],
			},
			{
				path: "api",
				name: "ApiPage",
				meta: {
					needLogin: true, // 需要登录
				},
				component: () => import("@/views/manage/api/index.vue"),
			},
			{
				path: "rpa",
				name: "RPA",
				meta: {
					needLogin: true,
				},
				redirect: { name: "RpaManage" },
				component: () => import("@/views/manage/rpa/index.vue"),
				children: [
					{
						path: "manage",
						name: "RpaManage",
						component: () => import("@/views/manage/rpa/manage/index.vue"),
						meta: {
							needLogin: true,
						},
					},
					{
						path: "create/:id?",
						name: "RpaCreate",
						component: () => import("@/views/manage/rpa/create/index.vue"),
						meta: {
							needLogin: true,
						},
					},
					{
						path: "log",
						name: "RpaLog",
						component: () => import("@/views/manage/rpa/log/index.vue"),
						meta: {
							needLogin: true,
						},
					},
					{
						path: "plan",
						name: "RpaPlan",
						component: () => import("@/views/manage/rpa/plan/index.vue"),
						meta: {
							needLogin: true,
						},
					},
				],
			},
		],
	},
	//插件
	{
		path: "/extension",
		name: "extension",
		component: () => import("@/views/extension/index.vue"),
		redirect: { path: "/extension/home" },
		meta: {
			needLogin: true, // 需要登录
		},
		children: [
			{
				path: "home",
				name: "extension_home",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import("@/views/extension/home/home.vue"),
			},
			{
				path: "category",
				name: "extension_category",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import("@/views/extension/category/category.vue"),
			},
			{
				path: "search",
				name: "extension_search",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import("@/views/extension/search/search.vue"),
			},
			{
				path: "leaderboard",
				name: "extension_leaderboard",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import("@/views/extension/leaderboard/leaderboard.vue"),
			},
			{
				path: "detail",
				name: "extension_detail",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import("@/views/extension/detail/detail.vue"),
			},
			{
				path: "extensionManage",
				name: "extension_extensionManage",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import("@/views/extension/extensionManage/extensionManage.vue"),
			},
			{
				path: "crossNavigation",
				name: "extension_crossNavigation",
				meta: {
					needLogin: true, // 需要登录
					ismember: true, //员工可见
				},
				component: () => import("@/views/extension/crossNavigation/crossNavigation.vue"),
			},
		],
	},
	{
		path: "/select",
		name: "select",
		meta: {
			needLogin: true, // 需要登录
			ismember: true, //员工可见
		},
		component: () => import("@/views/select/select.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/login/index.vue"),
		redirect: { name: "login_login" },
		meta: {
			needLogin: false, // 不需要登录
		},
		children: [
			{
				path: "login",
				name: "login_login",
				meta: {
					needLogin: false, // 不需要登录
				},
				component: () => import(/* webpackChunkName: "login_login" */ "@/views/login/login.vue"),
			},
			{
				path: "register",
				name: "login_register",
				meta: {
					needLogin: false, // 不需要登录
				},
				component: () => import(/* webpackChunkName: "login_register" */ "@/views/login/register.vue"),
			},
			{
				path: "agre",
				name: "login_agre",
				meta: {
					needLogin: false, // 不需要登录
				},
				component: () => import(/* webpackChunkName: "login_agre" */ "@/views/login/movs/agre.vue"),
			},
			{
				path: "ckop",
				name: "login_ckop",
				meta: {
					needLogin: false, // 不需要登录
				},
				component: () => import(/* webpackChunkName: "login_ckop" */ "@/views/login/movs/ckop.vue"),
			},
			{
				path: "company",
				name: "login_company",
				meta: {
					needLogin: false, // 不需要登录
				},
				component: () => import(/* webpackChunkName: "login_company" */ "@/views/login/login_company.vue"),
			},
		],
	},
	// 测试
	{
		path: "/test",
		name: "test",
		component: () => import("@/App.vue"),
		redirect: { name: "test_battery" },
		meta: {
			needLogin: false, // 需要登录
		},
		children: [
			{
				path: "battery",
				name: "test_battery",
				component: () => import("@/views/test/battery.vue"),
				meta: {
					needLogin: false, // 需要登录
					ismember: true, //员工可见
				},
			},
			{
				path: "rpa",
				name: "test_rpa",
				component: () => import("@/views/test/rpa.vue"),
				meta: {
					needLogin: false, // 需要登录
					ismember: true, //员工可见
				},
			},
			{
				path: "cookie",
				name: "test_cookie",
				component: () => import("@/views/test/cookie.vue"),
				meta: {
					needLogin: false, // 需要登录
					ismember: true, //员工可见
				},
			},
		],
	},
];

const router = new VueRouter({
	routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
	let isLogin = localStorage.token;
	next();
	// 判断该路由是否需要登录权限
	if (to.meta.needLogin) {
		// 判断是否已经登录
		if (isLogin) {
			let c_3 = "";
			if (localStorage.member) {
				c_3 = JSON.parse(localStorage.member).user_role;
			} else {
				c_3 = 0;
			}

			if (to.meta.ismember == undefined && c_3 != 0) {
				Vue.prototype.$message.error("没有权限");
				next({
					path: "/manage/home",
				});
				location.reload();
				return;
			}

			next();
		} else {
			Vue.prototype.$message.error("请先登录，然后进行查看");
			next({
				path: "/login",
			});
		}
	} else {
		next();
	}
});

export default router;
